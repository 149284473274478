import React from "react";
import style from "./logo.module.styl"

export default ({ className }) => {
	return (
		<svg className={style.root} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="250px"
			height="144.03px" viewBox="0 0 250 144.03"
		>
			<g >
				<path className="bg" d="M248.01,40.19c-0.39-0.33-0.85-0.62-1.36-0.86c-1.02-0.48-2.11-0.72-3.24-0.72c-2.12,0-3.93,0.83-5.52,1.56
		l-0.54,0.25c-5.07,2.27-10.86,3.18-16.46,4.07c-1.26,0.2-2.51,0.4-3.75,0.61c-1.22,0.21-2.6,0.44-3.96,0.93
		c-1.33,0.48-2.51,1.15-3.55,1.75c-0.93,0.53-1.86,1.14-2.76,1.72c-1.24,0.81-2.53,1.65-3.7,2.2c-1.43,0.67-2.87,1.32-4.33,1.96
		c0.88-0.56,1.79-1.17,2.68-1.82c1.02-0.75,1.94-1.55,2.83-2.33c1.05-0.91,2.04-1.78,3.08-2.46c2.65-1.73,5.28-3.11,7.81-4.11
		c0.99-0.39,2.07-0.68,3.21-0.98c1.05-0.28,2.14-0.56,3.24-0.96c2.03-0.73,3.95-1.63,5.81-2.5c2.28-1.07,4.43-2.08,6.67-2.74
		c0.55-0.16,1.1-0.3,1.64-0.44c1.78-0.46,3.8-0.98,5.72-2.17c2.45-1.52,4.23-3.71,5.01-6.16c0.86-2.68,0.26-5.3-1.54-6.85
		c-1.02-0.88-2.35-1.34-3.85-1.34c-0.37,0-0.75,0.03-1.13,0.09c-0.91,0.14-1.7,0.41-2.4,0.65c-0.23,0.08-0.45,0.16-0.68,0.23
		c-1.46,0.45-3.13,0.46-4.89,0.46c-0.52,0-1.05,0-1.57,0.01c-0.71,0.02-1.43,0.06-2.14,0.13c1.62-1.02,2.94-2.22,3.98-3.62
		c1.64-2.2,2.66-5.6,1.26-8.24c-1.04-1.96-3.3-3.17-5.9-3.17c0,0,0,0,0,0c-0.33,0-0.66,0.02-0.98,0.06
		c-1.26,0.16-2.38,0.56-3.36,0.92c-0.49,0.18-0.95,0.34-1.38,0.46c-0.6,0.17-1.27,0.28-1.98,0.4l-0.19,0.03
		c-4.73,0.8-10.31,1.89-15.78,3.8c-0.33,0.11-0.65,0.23-0.98,0.35c-0.94,0.34-1.83,0.65-2.71,0.91c-0.46,0.13-0.98,0.22-1.54,0.31
		c-0.63,0.11-1.34,0.23-2.06,0.43c-2.38,0.66-4.57,1.74-6.68,2.78c-0.5,0.25-0.99,0.49-1.48,0.72c-2.92,1.4-5.68,3.28-7.99,4.94
		c-2.67,1.92-4.99,3.47-7.59,4.78c-0.69,0.35-1.39,0.69-2.1,1.04c-2.04,1-4.15,2.03-6.13,3.23c-1.33,0.8-2.66,1.61-3.99,2.42
		c-1.28,0.78-2.52,1.65-3.73,2.49c-0.95,0.66-1.85,1.29-2.7,1.83c-1.94,1.23-3.9,2.72-6.02,4.55c-1.4,1.21-3.12,2.12-4.94,3.09
		l-0.34,0.18c-0.19,0.1-0.39,0.21-0.58,0.32c-0.69,0.38-1.56,0.86-2.01,0.86c-0.05,0-0.1-0.01-0.16-0.03
		c-0.11-0.14-0.21-0.87,0.04-1.69c0.85-2.8,2.77-5.31,4.81-7.96c0.41-0.54,0.83-1.08,1.23-1.61c1.15-1.55,1.95-3.14,2.72-4.68
		c0.23-0.46,0.46-0.92,0.7-1.38c0.25-0.48,0.5-0.95,0.76-1.42c0.95-1.75,1.93-3.56,2.6-5.61c1.69-5.14,1.39-10.6,0.85-14.27
		C153.54,4.52,151.44,0,147.48,0c-1.11,0-2.18,0.37-3.2,1.09c-0.8,0.57-1.48,1.31-2.02,2.21c-0.82,1.35-1.58,2.76-2.32,4.12
		c-1.7,3.15-3.31,6.12-5.54,8.47c-0.61,0.65-1.31,1.25-2.04,1.89c-1.47,1.28-3.14,2.72-4.36,4.81c-0.36,0.62-0.72,1.25-1.08,1.88
		c-0.65,1.13-1.27,2.24-1.92,3.29c-0.65-1.06-1.28-2.16-1.92-3.3c-0.36-0.63-0.72-1.26-1.08-1.88c-1.22-2.09-2.88-3.53-4.36-4.81
		c-0.73-0.64-1.43-1.24-2.04-1.89c-2.23-2.36-3.84-5.33-5.54-8.47c-0.74-1.36-1.5-2.77-2.32-4.12c-0.54-0.89-1.22-1.64-2.02-2.21
		C104.7,0.37,103.62,0,102.52,0c-3.96,0-6.06,4.52-6.51,7.56c-0.54,3.67-0.84,9.13,0.85,14.27c0.67,2.05,1.66,3.86,2.6,5.61
		c0.26,0.47,0.51,0.94,0.76,1.42c0.24,0.46,0.47,0.92,0.7,1.38c0.77,1.54,1.56,3.14,2.72,4.68c0.4,0.54,0.81,1.07,1.23,1.61
		c2.03,2.65,3.95,5.16,4.81,7.96c0.25,0.81,0.15,1.54,0.03,1.7c-0.06,0.01-0.1,0.02-0.15,0.02c-0.45,0-1.31-0.48-2.01-0.86
		c-0.19-0.11-0.39-0.21-0.58-0.32l-0.34-0.18c-1.82-0.97-3.54-1.88-4.94-3.09c-2.11-1.83-4.08-3.31-6.02-4.55
		c-0.85-0.55-1.75-1.17-2.7-1.83c-1.21-0.84-2.45-1.71-3.73-2.49c-1.32-0.81-2.65-1.61-3.98-2.42c-1.99-1.2-4.1-2.24-6.14-3.24
		c-0.7-0.34-1.4-0.69-2.1-1.04c-2.6-1.31-4.92-2.86-7.59-4.78c-2.31-1.66-5.06-3.54-7.99-4.94c-0.49-0.23-0.99-0.48-1.48-0.72
		c-2.12-1.04-4.3-2.12-6.68-2.78c-0.72-0.2-1.43-0.32-2.06-0.43c-0.56-0.09-1.08-0.18-1.54-0.31c-0.89-0.25-1.77-0.57-2.71-0.91
		c-0.33-0.12-0.65-0.23-0.98-0.35c-5.47-1.91-11.05-3-15.78-3.8L30,7.16c-0.71-0.12-1.38-0.23-1.98-0.4
		c-0.43-0.12-0.89-0.29-1.38-0.46c-0.98-0.36-2.1-0.76-3.37-0.92c-0.32-0.04-0.65-0.06-0.98-0.06c-2.6,0-4.86,1.22-5.9,3.17
		c-1.41,2.64-0.38,6.04,1.26,8.24c1.04,1.4,2.36,2.6,3.98,3.62c-0.71-0.07-1.42-0.11-2.14-0.13c-0.52-0.01-1.05-0.01-1.57-0.01
		c-1.77,0-3.43-0.01-4.89-0.46c-0.23-0.07-0.46-0.15-0.68-0.23c-0.7-0.24-1.49-0.51-2.4-0.65c-0.38-0.06-0.75-0.09-1.13-0.09
		c-1.5,0-2.83,0.46-3.85,1.34c-1.81,1.55-2.4,4.17-1.54,6.85c0.78,2.45,2.56,4.64,5.01,6.16c1.92,1.19,3.94,1.71,5.72,2.17
		c0.55,0.14,1.1,0.28,1.64,0.44c2.25,0.66,4.4,1.67,6.67,2.74c1.86,0.87,3.78,1.78,5.81,2.5c1.1,0.39,2.19,0.68,3.24,0.96
		c1.14,0.3,2.22,0.58,3.21,0.98c2.53,1,5.16,2.39,7.81,4.11c1.04,0.68,2.03,1.54,3.08,2.46c0.89,0.78,1.81,1.58,2.83,2.33
		c0.89,0.65,1.8,1.26,2.68,1.82c-1.45-0.63-2.9-1.29-4.33-1.96c-1.17-0.55-2.45-1.39-3.7-2.2c-0.9-0.59-1.83-1.19-2.76-1.72
		c-1.05-0.6-2.23-1.27-3.55-1.75c-1.36-0.49-2.74-0.72-3.96-0.93c-1.24-0.21-2.5-0.41-3.76-0.61c-5.6-0.88-11.39-1.79-16.46-4.07
		l-0.54-0.25c-1.6-0.73-3.41-1.56-5.52-1.56c-1.13,0-2.22,0.24-3.24,0.72c-0.51,0.24-0.96,0.53-1.36,0.86
		c-2.39,2.03-2.65,5.41-0.67,8.41c1.13,1.71,2.64,2.72,3.98,3.62l0.15,0.1l0.33,0.22c1.34,0.9,2.86,1.93,4.52,2.66
		c0.53,0.23,1.03,0.43,1.52,0.62c0.89,0.35,1.65,0.64,2.34,1.07c2.55,1.56,5.58,3.3,9.23,4.24c0.23,0.06,0.51,0.16,0.8,0.26
		c0.39,0.14,0.84,0.3,1.33,0.42c0.53,0.13,1.04,0.22,1.5,0.3c0.42,0.07,0.81,0.14,1.15,0.23c1.42,0.39,2.93,0.98,4.46,1.75
		c1.57,0.79,3.06,1.78,4.64,2.81c1.13,0.75,2.29,1.51,3.51,2.22c-0.75-0.3-1.51-0.59-2.29-0.84c-2.3-0.73-4.63-1.18-6.87-1.61
		c-0.43-0.08-0.86-0.17-1.29-0.25c-0.98-0.19-1.9-0.43-2.75-0.71c-3.09-1.03-6.17-2.4-8.81-3.6c-0.47-0.21-0.92-0.37-1.31-0.51
		c-0.35-0.12-0.67-0.24-0.92-0.36c-0.36-0.18-0.72-0.37-1.07-0.57c-0.48-0.26-0.97-0.53-1.51-0.79c-1.05-0.5-2.09-0.75-3.09-0.75
		c-1.4,0-2.72,0.49-3.83,1.43c-1.6,1.35-2.52,3.39-2.45,5.48c0.14,4.58,4.43,7.11,6.05,8.06c1.61,0.95,3.27,1.83,4.87,2.67
		c0.96,0.51,1.93,1.02,2.88,1.55c0.62,0.34,1.23,0.69,1.84,1.04c1.93,1.1,3.92,2.24,6.17,3.14l0.03,0.01l0.03,0.01
		c2.81,1.06,6.5,2.47,10.03,4.09c1.17,0.54,2.4,1.19,3.97,2.11c1.99,1.16,3.97,1.93,5.89,2.67l0.46,0.18
		c3.6,1.4,7.36,2.93,10.44,4.99c1.39,0.93,2.79,1.94,4.15,2.92c1.82,1.31,3.7,2.67,5.66,3.92c1.04,0.66,2.1,1.25,3.12,1.82
		c1,0.56,1.94,1.08,2.85,1.66c2.71,1.73,5.32,3.63,7.52,5.27c0.83,0.62,1.65,1.24,2.47,1.86c4,3.02,8.13,6.15,12.8,8.55
		c4.92,2.53,10.81,5.32,17.21,6.86l0.46,0.11c0.75,0.18,1.52,0.37,2.33,0.52c0.58,0.11,1.1,0.19,1.61,0.24
		c0.06,0.01,0.14,0.01,0.22,0.02c-0.16,1.21-0.24,2.4-0.21,3.56c0.19,9.44,5.85,10.17,7.58,10.17c1.73,0,7.4-0.73,7.58-10.17
		c0.02-1.17-0.05-2.35-0.21-3.56c0.08-0.01,0.16-0.01,0.22-0.02c0.51-0.05,1.03-0.13,1.61-0.24c0.8-0.15,1.58-0.34,2.33-0.52
		l0.46-0.11c6.4-1.54,12.29-4.33,17.21-6.86c4.66-2.4,8.8-5.52,12.79-8.55c0.82-0.62,1.64-1.24,2.47-1.86
		c2.2-1.63,4.81-3.54,7.52-5.27c0.9-0.57,1.85-1.1,2.85-1.66c1.02-0.57,2.08-1.16,3.12-1.82c1.96-1.25,3.84-2.6,5.66-3.92
		c1.36-0.98,2.76-1.99,4.15-2.92c3.08-2.06,6.85-3.6,10.44-4.99l0.46-0.18c1.92-0.74,3.9-1.51,5.89-2.67
		c1.57-0.92,2.8-1.57,3.97-2.11c3.53-1.62,7.22-3.04,10.03-4.09l0.03-0.01l0.03-0.01c2.25-0.9,4.24-2.04,6.17-3.14
		c0.61-0.35,1.22-0.7,1.84-1.04c0.95-0.53,1.92-1.04,2.88-1.55c1.6-0.85,3.25-1.72,4.87-2.67c1.61-0.95,5.9-3.48,6.05-8.06
		c0.07-2.08-0.85-4.13-2.45-5.48c-1.11-0.93-2.43-1.43-3.83-1.43c-1,0-2.04,0.25-3.09,0.75c-0.54,0.26-1.03,0.53-1.51,0.79
		c-0.35,0.2-0.71,0.39-1.07,0.57c-0.25,0.12-0.57,0.24-0.92,0.36c-0.4,0.14-0.85,0.3-1.31,0.51c-2.64,1.2-5.72,2.57-8.81,3.6
		c-0.84,0.28-1.77,0.52-2.75,0.71c-0.43,0.09-0.86,0.17-1.29,0.25c-2.25,0.43-4.57,0.88-6.87,1.61c-0.78,0.25-1.54,0.53-2.29,0.84
		c1.22-0.71,2.37-1.47,3.51-2.22c1.58-1.04,3.07-2.02,4.64-2.81c1.53-0.78,3.03-1.37,4.46-1.75c0.33-0.09,0.73-0.16,1.15-0.23
		c0.45-0.08,0.97-0.17,1.5-0.3c0.49-0.12,0.94-0.28,1.33-0.42c0.3-0.1,0.58-0.2,0.8-0.26c3.64-0.94,6.67-2.68,9.23-4.24
		c0.69-0.42,1.46-0.72,2.35-1.07c0.49-0.19,0.99-0.39,1.52-0.62c1.66-0.73,3.18-1.75,4.52-2.66l0.33-0.22l0.15-0.1
		c1.33-0.89,2.84-1.91,3.97-3.62C250.66,45.6,250.39,42.22,248.01,40.19z"/>
				<g>
					<g>
						<path d="M125,118.71c-0.12,0-0.17,0.15-0.23,0.25c-2.49,4.49-4.53,9.63-4.43,14.84c0.11,5.3,2.2,7.3,4.66,7.3
				c2.46,0,4.55-2,4.66-7.3c0.1-5.21-1.94-10.35-4.43-14.84C125.17,118.86,125.12,118.71,125,118.71z"/>
						<path d="M246.11,42.42c-0.21-0.18-0.46-0.33-0.71-0.45c-2.39-1.13-4.68,0.13-6.85,1.1c-6.58,2.95-13.91,3.7-20.92,4.89
				c-1.17,0.2-2.34,0.4-3.46,0.8c-1.09,0.39-2.09,0.96-3.1,1.53c-2.15,1.23-4.42,2.97-6.66,4.02c-3.17,1.49-6.39,2.88-9.64,4.18
				c-0.35,0.14-0.69,0.29-1.01,0.47c-0.97,0.54-1.93,0.98-2.99,1.32c-0.8,0.26-1.8,0.72-2.65,0.68c-0.66-0.03-1.36-0.44-1.49-1.09
				c-0.31-1.47,1.84-3.28,2.84-4.07c1.53-1.21,3.42-2.04,5.11-3.03c1.79-1.04,3.55-2.12,5.22-3.34c2.09-1.53,3.88-3.47,6.04-4.87
				c2.62-1.71,5.42-3.23,8.33-4.38c2.14-0.85,4.4-1.2,6.55-1.97c4.3-1.54,8.24-3.99,12.64-5.29c2.29-0.68,4.58-1.01,6.65-2.29
				c1.71-1.06,3.15-2.64,3.77-4.56c0.41-1.28,0.36-2.86-0.66-3.73c-0.7-0.6-1.71-0.72-2.62-0.58c-0.91,0.14-1.77,0.51-2.65,0.78
				c-2.37,0.74-4.83,0.55-7.27,0.6c-2.5,0.05-4.99,0.49-7.37,1.27c-2.75,0.9-5.1,2.61-7.82,3.53c-2.02,0.68-4.15,1.07-6.18,1.71
				c-0.74,0.21-1.49,0.42-2.23,0.63c-0.79,0.21-1.89,0.77-2.72,0.77c-2.47,0.7-5.13,1.31-7.23,2.85c-1.26,0.92-2.27,2.17-3.61,2.99
				c-0.26,0.16-0.57,0.28-0.81,0.47c-1.71,1.35-3.45,2.66-5.22,3.93c-1.81,1.3-4.15,2.13-6.08,3.25c-0.73,0.42-1.52,0.85-2.38,0.57
				c-0.43-0.14-0.76-0.52-0.79-0.97c-0.04-0.57,0.4-0.88,0.8-1.16c3.88-2.67,7.62-5.67,11.05-8.83c3.54-3.28,7.86-4.83,12.4-6.26
				c3.64-1.14,6.73-3.07,10.08-4.82c2.59-1.35,5.32-1.98,8.12-2.69c3.5-0.89,7.21-2.41,9.44-5.41c0.95-1.28,1.85-3.56,1.02-5.12
				c-0.71-1.34-2.55-1.76-3.93-1.58c-1.5,0.19-2.88,0.89-4.33,1.3c-0.81,0.23-1.64,0.36-2.47,0.5c-5.18,0.88-10.34,1.94-15.31,3.68
				c-1.28,0.45-2.55,0.94-3.86,1.31c-1.19,0.34-2.43,0.42-3.62,0.75c-2.65,0.73-5.21,2.14-7.68,3.32c-2.64,1.26-5.17,2.97-7.54,4.68
				c-2.59,1.86-5.12,3.57-7.98,5.01c-2.69,1.35-5.49,2.63-8.03,4.16c-1.32,0.8-2.65,1.6-3.97,2.41c-2.18,1.34-4.37,3.01-6.39,4.3
				c-2,1.28-3.88,2.74-5.68,4.29c-1.75,1.51-3.79,2.56-5.82,3.64c-1.61,0.86-3.33,2.03-5.21,1.28c-2.08-0.83-2.24-3.4-1.67-5.26
				c1.2-3.95,4.06-7.22,6.49-10.47c1.32-1.77,2.15-3.73,3.17-5.67c1.13-2.16,2.41-4.26,3.18-6.59c1.34-4.08,1.36-8.71,0.73-12.93
				c-0.32-2.19-2.18-6.6-5.11-4.51c-0.5,0.35-0.9,0.82-1.22,1.34c-2.63,4.35-4.69,9.34-8.24,13.09c-2.02,2.13-4.49,3.58-5.99,6.16
				c-1.57,2.69-3.04,5.47-4.87,7.99c-0.2,0.27-0.44,0.48-0.66,0.72c-0.23-0.24-0.47-0.45-0.66-0.72c-1.83-2.52-3.31-5.31-4.87-7.99
				c-1.51-2.58-3.98-4.03-5.99-6.16c-3.55-3.75-5.61-8.74-8.24-13.09c-0.31-0.52-0.72-0.98-1.22-1.34
				c-2.93-2.09-4.79,2.32-5.11,4.51c-0.62,4.22-0.61,8.85,0.73,12.93c0.77,2.33,2.05,4.43,3.18,6.59c1.01,1.94,1.85,3.89,3.17,5.67
				c2.42,3.25,5.28,6.53,6.49,10.47c0.57,1.86,0.41,4.43-1.67,5.26c-1.89,0.75-3.61-0.42-5.21-1.28c-2.02-1.08-4.07-2.13-5.82-3.64
				c-1.8-1.55-3.67-3.02-5.68-4.29c-2.02-1.29-4.21-2.96-6.39-4.3c-1.32-0.81-2.64-1.61-3.97-2.41c-2.54-1.53-5.35-2.81-8.03-4.16
				c-2.86-1.44-5.39-3.15-7.98-5.01c-2.38-1.71-4.9-3.41-7.54-4.68c-2.47-1.18-5.03-2.59-7.68-3.32c-1.18-0.33-2.42-0.41-3.62-0.75
				c-1.31-0.37-2.58-0.86-3.86-1.31c-4.97-1.74-10.13-2.8-15.31-3.68c-0.83-0.14-1.66-0.27-2.47-0.5c-1.45-0.41-2.83-1.11-4.33-1.3
				c-1.37-0.18-3.21,0.24-3.93,1.58c-0.83,1.56,0.07,3.84,1.02,5.12c2.23,3,5.94,4.51,9.44,5.41c2.8,0.71,5.53,1.34,8.12,2.69
				c3.35,1.75,6.44,3.68,10.08,4.82c4.54,1.42,8.85,2.98,12.4,6.26c3.42,3.17,7.16,6.16,11.05,8.83c0.4,0.28,0.83,0.59,0.8,1.16
				c-0.03,0.45-0.36,0.83-0.79,0.97c-0.85,0.28-1.65-0.15-2.38-0.57c-1.93-1.11-4.27-1.94-6.08-3.25c-1.77-1.27-3.51-2.58-5.22-3.93
				c-0.24-0.19-0.55-0.31-0.81-0.47c-1.34-0.82-2.35-2.07-3.61-2.99c-2.1-1.54-4.77-2.15-7.23-2.85c-0.83,0-1.93-0.56-2.72-0.77
				c-0.74-0.21-1.49-0.42-2.23-0.63c-2.03-0.64-4.16-1.03-6.18-1.71c-2.73-0.92-5.08-2.62-7.82-3.53c-2.38-0.78-4.87-1.21-7.37-1.27
				c-2.44-0.05-4.9,0.13-7.27-0.6c-0.88-0.27-1.74-0.64-2.65-0.78c-0.91-0.14-1.92-0.02-2.62,0.58c-1.02,0.87-1.07,2.46-0.66,3.73
				c0.61,1.92,2.06,3.5,3.77,4.56c2.07,1.28,4.36,1.62,6.65,2.29c4.41,1.3,8.34,3.76,12.64,5.29c2.14,0.77,4.41,1.12,6.55,1.97
				c2.91,1.15,5.71,2.67,8.33,4.38c2.16,1.41,3.95,3.34,6.04,4.87c1.67,1.22,3.43,2.3,5.22,3.34c1.69,0.98,3.58,1.82,5.11,3.03
				c1,0.79,3.14,2.6,2.84,4.07c-0.14,0.65-0.83,1.06-1.49,1.09c-0.85,0.04-1.84-0.42-2.65-0.68c-1.06-0.34-2.02-0.78-2.99-1.32
				c-0.33-0.18-0.67-0.33-1.01-0.47c-3.26-1.3-6.47-2.69-9.64-4.18c-2.24-1.05-4.51-2.8-6.66-4.02c-1-0.57-2.01-1.14-3.1-1.53
				c-1.11-0.4-2.29-0.6-3.46-0.8c-7.01-1.19-14.34-1.94-20.92-4.89c-2.17-0.97-4.46-2.23-6.85-1.1c-0.25,0.12-0.49,0.26-0.71,0.45
				c-1.51,1.28-1,3.25-0.12,4.57c0.84,1.27,2.07,2.06,3.31,2.9c1.4,0.94,2.86,1.95,4.4,2.63c1.43,0.63,2.86,1.04,4.21,1.87
				c2.71,1.65,5.35,3.11,8.43,3.9c0.71,0.18,1.41,0.5,2.12,0.68c0.88,0.22,1.81,0.3,2.69,0.54c1.74,0.47,3.41,1.15,5.01,1.96
				c3.69,1.86,6.85,4.59,10.6,6.33c1.6,0.74,3.3,1.29,4.8,2.23c1.22,0.77,1.88,1.78,2.84,2.83c0.49,0.54,3.99,2.77,3.27,3.61
				c-0.17,0.2-0.45,0.26-0.71,0.28c-1.62,0.17-3.34-0.6-4.92-0.95c-1.64-0.37-3.33-0.77-4.83-1.48c-2.42-1.16-4.84-2.42-7.39-3.23
				c-2.56-0.81-5.22-1.26-7.85-1.78c-1.05-0.21-2.09-0.47-3.1-0.81c-3.12-1.03-6.12-2.35-9.1-3.71c-0.75-0.34-1.56-0.53-2.31-0.9
				c-0.86-0.42-1.68-0.93-2.54-1.34c-1.29-0.61-2.6-0.72-3.78,0.27c-0.92,0.77-1.45,1.94-1.41,3.14c0.08,2.63,2.55,4.42,4.61,5.63
				c2.51,1.48,5.12,2.77,7.67,4.18c2.57,1.42,4.95,2.93,7.69,4.03c3.43,1.29,6.9,2.65,10.22,4.17c1.45,0.66,2.85,1.43,4.23,2.24
				c1.94,1.13,3.88,1.85,5.93,2.65c3.72,1.45,7.68,3.06,11.01,5.29c3.29,2.2,6.41,4.68,9.75,6.8c1.94,1.23,4.02,2.24,5.97,3.47
				c2.64,1.68,5.18,3.52,7.7,5.39c4.83,3.6,9.49,7.39,14.85,10.15c5.29,2.72,10.74,5.22,16.55,6.61c0.88,0.21,1.76,0.44,2.66,0.61
				c0.46,0.09,0.91,0.16,1.38,0.21c0.48,0.05,0.64,0.06,0.83-0.4c0.51-1.27,1-2.55,1.52-3.82c0.77-1.89,1.64-3.78,2.81-5.46
				c0.48-0.68,1.06-1.57,1.86-1.94c0.17-0.08,0.36-0.08,0.53,0c0.8,0.38,1.38,1.26,1.86,1.94c1.17,1.67,2.04,3.57,2.81,5.46
				c0.52,1.27,1.01,2.55,1.52,3.82c0.19,0.46,0.35,0.45,0.82,0.4c0.46-0.05,0.92-0.12,1.38-0.21c0.89-0.17,1.77-0.39,2.66-0.61
				c5.81-1.39,11.26-3.89,16.55-6.61c5.36-2.76,10.02-6.55,14.85-10.15c2.51-1.87,5.05-3.7,7.7-5.39c1.95-1.24,4.03-2.24,5.97-3.47
				c3.34-2.13,6.46-4.6,9.75-6.8c3.33-2.23,7.29-3.84,11.01-5.29c2.05-0.8,3.99-1.52,5.93-2.65c1.38-0.81,2.78-1.58,4.23-2.24
				c3.32-1.52,6.79-2.88,10.22-4.17c2.74-1.1,5.12-2.61,7.69-4.03c2.55-1.41,5.17-2.7,7.68-4.18c2.06-1.21,4.52-3.01,4.61-5.63
				c0.04-1.2-0.49-2.37-1.41-3.14c-1.18-0.99-2.49-0.88-3.78-0.27c-0.87,0.41-1.68,0.92-2.54,1.34c-0.75,0.37-1.56,0.56-2.31,0.9
				c-2.99,1.36-5.99,2.68-9.1,3.71c-1.02,0.34-2.05,0.6-3.1,0.81c-2.63,0.52-5.29,0.97-7.85,1.78c-2.56,0.81-4.98,2.07-7.4,3.23
				c-1.5,0.72-3.19,1.12-4.83,1.48c-1.58,0.35-3.3,1.12-4.92,0.95c-0.26-0.03-0.54-0.08-0.71-0.28c-0.72-0.84,2.78-3.07,3.27-3.61
				c0.96-1.05,1.62-2.06,2.84-2.83c1.49-0.94,3.19-1.49,4.8-2.23c3.75-1.73,6.92-4.46,10.6-6.33c1.6-0.81,3.28-1.49,5.01-1.96
				c0.89-0.24,1.81-0.32,2.69-0.54c0.71-0.18,1.41-0.5,2.12-0.68c3.09-0.8,5.73-2.25,8.43-3.9c1.36-0.83,2.78-1.24,4.21-1.87
				c1.55-0.68,3-1.69,4.4-2.63c1.24-0.83,2.47-1.63,3.31-2.9C247.1,45.67,247.62,43.71,246.11,42.42z M191.68,77.26
				c-1.6,2.08-3.54,3.61-5.72,5.04c-3.4,2.24-6.92,4.3-10.54,6.16c-3.69,1.9-7.44,3.48-11.31,5c-7.67,3.01-15.61,5.75-22.88,9.65
				c-1.83,0.98-3.57,2.1-5.25,3.31c-1.42,1.03-2.81,2.04-4.27,3.04c-1.53,1.05-3.01,2.18-4.31,3.51c-0.76,0.78-1.55,1.63-2.4,2.31
				c0,0,0,0,0,0h0c0,0,0,0,0,0c-0.85-0.68-1.64-1.53-2.4-2.31c-1.3-1.33-2.78-2.46-4.31-3.51c-1.45-1-2.84-2.01-4.27-3.04
				c-1.68-1.21-3.43-2.33-5.25-3.31c-7.27-3.91-15.21-6.64-22.88-9.65c-3.86-1.52-7.61-3.1-11.31-5c-3.62-1.87-7.14-3.93-10.54-6.16
				c-2.18-1.43-4.11-2.97-5.72-5.04c-0.06-0.08-0.13-0.18-0.11-0.29c0.02-0.09,0.1-0.14,0.18-0.18c5.25-2.26,9.84-5.88,14.57-9.05
				c4.07-2.72,8.46-5.13,12.8-7.39c2.53-1.32,5.2-2.33,7.77-3.56c3.17-1.53,6.46-2.59,9.8-3.67c2.17-0.7,4.31-1.49,6.49-2.17
				c0.94-0.55,1.91-1.06,2.91-1.5c0,0,0,0,0,0l0.04-0.02c1.76-0.79,3.62-1.41,5.54-1.85l0,0l0.14-0.03
				c0.32-0.07,0.63-0.13,0.95-0.19l0.08-0.02l0,0c1.56-0.29,3.16-0.46,4.79-0.5c0,0,0-0.01,0-0.01h0.64c0.03,0,0.05,0,0.08,0
				c0.03,0,0.05,0,0.08,0h0.4c0,0,0,0,0,0.01c1.54,0.02,3.05,0.16,4.52,0.41l0-0.01l0.19,0.04c0.33,0.06,0.65,0.12,0.97,0.19l0.02,0
				l0,0c1.59,0.33,3.14,0.79,4.63,1.37c0,0,0,0,0,0l0.05,0.02c1.5,0.58,2.95,1.29,4.33,2.1c2.18,0.68,4.32,1.47,6.49,2.17
				c3.35,1.08,6.63,2.15,9.8,3.67c2.57,1.24,5.24,2.24,7.77,3.56c4.34,2.26,8.73,4.67,12.8,7.39c4.74,3.17,9.32,6.79,14.57,9.05
				c0.08,0.03,0.16,0.09,0.18,0.18C191.81,77.08,191.74,77.18,191.68,77.26z"/>
					</g>
					<g>
						<path d="M131.92,88.98l1.43,2.03l-0.95-1.97C132.23,89.03,132.07,89.01,131.92,88.98z" />
						<polygon points="120.86,92.76 122.41,88.33 121.66,88.14 			" />
						<path d="M135.92,86.45l1.55,1.13l-1.48-1.5C135.98,86.2,135.95,86.32,135.92,86.45z" />
						<polygon points="126.21,93.24 126.25,88.55 125.48,88.61 			" />
						<path d="M129.14,60.87l-1.12,3.19c0.2-0.17,0.42-0.32,0.64-0.45L129.14,60.87z" />
						<path d="M135.37,71.17l3.83-2.7l-3.43,1.65c-0.15,0.3-0.32,0.59-0.52,0.85L135.37,71.17z" />
						<polygon points="136.33,80.15 140.94,80.95 136.52,79.4 			" />
						<polygon points="114.63,82.46 110.8,85.16 115.02,83.13 			" />
						<polygon points="119.35,66.44 116.65,62.61 118.68,66.84 			" />
						<path d="M135.76,64.34l-0.54,0.53c0.03,0.03,0.05,0.07,0.08,0.1L135.76,64.34z" />
						<polygon points="136.8,76.33 141.43,75.6 136.74,75.56 			" />
						<polygon points="114.24,89.28 117.58,86 117,85.49 			" />
						<polygon points="113.2,77.29 108.57,78.02 113.26,78.06 			" />
						<polygon points="116.32,68.81 112.53,66.05 115.81,69.4 			" />
						<polygon points="123.79,60.38 123.75,65.07 124.52,65.01 			" />
						<path d="M136.68,78.15l3.2,0.05l-3.15-0.55c0.02-0.28,0.03-0.56,0.03-0.84c0-1.09-0.15-2.13-0.43-3.13l3.45-1.4l-3.65,0.78
				c-0.24-0.71-0.55-1.4-0.91-2.04c-0.92,1.19-2.36,1.96-3.98,1.96c-2.78,0-5.04-2.26-5.04-5.04c0-1,0.29-1.92,0.79-2.71
				c-0.22-0.04-0.43-0.07-0.65-0.09l0.05-3.2l-0.55,3.15c-0.28-0.02-0.56-0.03-0.84-0.03c-1.09,0-2.13,0.15-3.13,0.43l-1.4-3.45
				l0.78,3.65c-1.3,0.44-2.49,1.1-3.54,1.93l-2.23-2.29l1.84,2.62c-1.01,0.87-1.87,1.92-2.53,3.09l-3.44-1.45l3.13,2.03
				c-0.59,1.19-0.98,2.5-1.14,3.87l-3.2-0.05l3.15,0.55c-0.02,0.28-0.03,0.56-0.03,0.84c0,1.09,0.15,2.13,0.43,3.13l-3.45,1.4
				l3.65-0.78c0.44,1.3,1.1,2.49,1.93,3.54l-2.29,2.23l2.62-1.84c0.87,1.01,1.92,1.87,3.09,2.53l-1.45,3.44l2.03-3.13
				c1.19,0.59,2.5,0.98,3.87,1.14l-0.05,3.2l0.55-3.15c0.28,0.02,0.56,0.03,0.84,0.03c1.09,0,2.13-0.15,3.13-0.43l1.4,3.45
				l-0.78-3.65c0.33-0.11,0.65-0.23,0.96-0.37c-0.4-0.57-0.64-1.26-0.64-2.01c0-1.92,1.56-3.48,3.48-3.48c0.93,0,1.78,0.37,2.4,0.97
				c0.09-0.15,0.18-0.3,0.27-0.45l3.44,1.45l-3.13-2.03C136.12,80.84,136.52,79.53,136.68,78.15z"/>
						<path d="M130,47.24L130,47.24c0.06,0.02,0.13,0.03,0.19,0.04L130,47.24z" />
						<path d="M130.75,49.09l0.06-0.23c-0.33-0.07-0.66-0.13-0.99-0.19l-0.04,0.35l-0.92,7.28L130.75,49.09z" />
						<path d="M119.19,104.77c0.33,0.07,0.66,0.13,0.99,0.19l0.96-7.63L119.19,104.77z" />
						<path d="M120,106.39L120,106.39c-0.06-0.01-0.12-0.02-0.18-0.03L120,106.39z" />
						<path d="M154.57,81.81L154.57,81.81c-0.02,0.07-0.03,0.14-0.04,0.2L154.57,81.81z" />
						<path d="M152.96,82.62c0.07-0.33,0.13-0.66,0.19-0.99l-7.63-0.96L152.96,82.62z" />
						<path d="M108.62,51.69l-0.07,0.05C108.57,51.73,108.59,51.71,108.62,51.69L108.62,51.69z" />
						<path d="M109.34,52.94c-0.28,0.19-0.56,0.37-0.83,0.57l4.72,6.08L109.34,52.94z" />
						<path d="M107.62,52.37L107.62,52.37c0.11-0.07,0.21-0.14,0.32-0.22L107.62,52.37z" />
						<path d="M150.12,60.43l-0.09-0.13C150.06,60.34,150.09,60.38,150.12,60.43L150.12,60.43z" />
						<path d="M149.45,59.43L149.45,59.43c0.08,0.13,0.17,0.25,0.25,0.37L149.45,59.43z" />
						<path d="M148.88,61.15c-0.19-0.28-0.37-0.56-0.57-0.83l-6.08,4.71L148.88,61.15z" />
						<path d="M140.66,100.69c0.28-0.19,0.56-0.37,0.83-0.57l-4.71-6.08L140.66,100.69z" />
						<path d="M142.38,101.26L142.38,101.26c-0.12,0.08-0.23,0.16-0.35,0.24L142.38,101.26z" />
						<path d="M141.38,101.93l0.11-0.07C141.46,101.88,141.42,101.9,141.38,101.93L141.38,101.93z" />
						<path d="M100.55,94.19L100.55,94.19c-0.07-0.1-0.13-0.2-0.2-0.3L100.55,94.19z" />
						<path d="M99.88,93.2l0.04,0.05C99.91,93.23,99.89,93.21,99.88,93.2L99.88,93.2z" />
						<path d="M101.12,92.47c0.19,0.28,0.37,0.56,0.57,0.83l6.08-4.72L101.12,92.47z" />
						<path d="M101.4,60.74c-0.19,0.28-0.38,0.56-0.56,0.84l6.69,3.82L101.4,60.74z" />
						<path d="M100.25,59.87l-0.03,0.05C100.23,59.91,100.24,59.89,100.25,59.87L100.25,59.87z" />
						<path d="M99.59,60.87L99.59,60.87c0.04-0.05,0.07-0.11,0.11-0.17L99.59,60.87z" />
						<path d="M118.32,47.57L118.32,47.57c0.05-0.01,0.09-0.02,0.14-0.03L118.32,47.57z" />
						<path d="M119.72,49.05l-0.04-0.3c-0.33,0.06-0.66,0.13-0.99,0.2l0.05,0.17l1.98,7.25L119.72,49.05z" />
						<path d="M141.96,52.08l-0.09-0.06C141.9,52.04,141.93,52.06,141.96,52.08L141.96,52.08z" />
						<path d="M140.96,51.42L140.96,51.42c0.06,0.04,0.12,0.08,0.18,0.12L140.96,51.42z" />
						<path d="M141.09,53.22c-0.28-0.19-0.56-0.38-0.84-0.56l-3.82,6.68L141.09,53.22z" />
						<path d="M154.24,70.15L154.24,70.15c0.01,0.06,0.02,0.13,0.04,0.19L154.24,70.15z" />
						<path d="M153.06,71.52c-0.06-0.33-0.13-0.66-0.2-0.99l-7.43,2.02L153.06,71.52z" />
						<path d="M97.04,71c-0.07,0.33-0.13,0.66-0.19,0.99l7.63,0.96L97.04,71z" />
						<path d="M95.43,71.81L95.43,71.81c0.01-0.05,0.02-0.11,0.03-0.16L95.43,71.81z" />
						<path d="M150.39,92.79L150.39,92.79c-0.04,0.06-0.08,0.12-0.12,0.18L150.39,92.79z" />
						<path d="M149.72,93.79l0.07-0.1C149.77,93.72,149.74,93.75,149.72,93.79L149.72,93.79z" />
						<path d="M148.58,92.92c0.19-0.28,0.38-0.56,0.56-0.84l-6.68-3.82L148.58,92.92z" />
						<path d="M131.64,106.06L131.64,106.06c-0.05,0.01-0.1,0.02-0.15,0.03L131.64,106.06z" />
						<path d="M130.28,104.87c0.33-0.06,0.66-0.13,0.99-0.2l-2.02-7.43L130.28,104.87z" />
						<path d="M96.93,82.07c0.06,0.33,0.13,0.66,0.2,0.99l7.43-2.01L96.93,82.07z" />
						<path d="M95.75,83.43L95.75,83.43c-0.01-0.04-0.02-0.09-0.03-0.13L95.75,83.43z" />
						<path d="M108.88,100.38c0.28,0.19,0.56,0.38,0.84,0.56l3.83-6.68L108.88,100.38z" />
						<path d="M108.01,101.52l0.07,0.05C108.05,101.55,108.03,101.54,108.01,101.52L108.01,101.52z" />
						<path d="M109.01,102.19L109.01,102.19c-0.05-0.04-0.1-0.07-0.15-0.1L109.01,102.19z" />
						<path d="M124.28,46.83C124.28,46.83,124.28,46.83,124.28,46.83c0.21,0,0.43,0,0.64,0H124.28z" />
						<path d="M125.48,46.83h-0.4C125.21,46.83,125.34,46.83,125.48,46.83C125.48,46.83,125.48,46.83,125.48,46.83z" />
						<path d="M125.26,48.94c0.02-0.23,0.05-0.46,0.07-0.67c-0.11,0-0.22,0-0.33,0c-0.19,0-0.38,0-0.57,0.01
				c0.02,0.21,0.05,0.44,0.07,0.67c0.15,1.42,0.33,3.04,0.38,3.04S125.11,50.37,125.26,48.94z"/>
						<path d="M135.8,48.83C135.8,48.83,135.8,48.83,135.8,48.83c0.02,0.01,0.03,0.01,0.05,0.02L135.8,48.83z" />
						<path d="M136.23,50.55c-0.28-0.12-0.56-0.23-0.84-0.34c-0.44,1.47-1.06,3.6-1.01,3.62C134.44,53.85,135.5,51.9,136.23,50.55z" />
						<path d="M145.41,56.85c-0.07-0.08-0.15-0.15-0.22-0.23c-0.14-0.14-0.28-0.28-0.42-0.41c-0.97,1.19-2.34,2.91-2.3,2.95
				C142.5,59.2,144.21,57.82,145.41,56.85z"/>
						<path d="M145.68,55.09C145.68,55.09,145.68,55.09,145.68,55.09c0.16,0.16,0.32,0.32,0.48,0.48L145.68,55.09z" />
						<path d="M146.53,55.94l-0.29-0.29C146.33,55.74,146.43,55.84,146.53,55.94C146.53,55.94,146.53,55.94,146.53,55.94z" />
						<path d="M152.42,64.65C152.42,64.65,152.41,64.65,152.42,64.65c0.01,0.04,0.03,0.08,0.05,0.11L152.42,64.65z" />
						<path d="M151.5,66.17c-0.11-0.28-0.23-0.56-0.35-0.84c-1.35,0.73-3.29,1.8-3.27,1.85C147.9,67.23,150.03,66.61,151.5,66.17z" />
						<path d="M154.98,77.25C154.98,77.25,154.99,77.26,154.98,77.25l0.01-0.4C154.98,76.99,154.98,77.12,154.98,77.25z" />
						<path d="M154.98,76.06C154.98,76.06,154.98,76.06,154.98,76.06c0,0.24,0,0.47,0,0.71L154.98,76.06z" />
						<path d="M149.83,76.66c0,0.06,2.19,0.29,3.72,0.45c0-0.1,0-0.2,0-0.3c0-0.2,0-0.41-0.01-0.61
				C152.01,76.37,149.83,76.6,149.83,76.66z"/>
						<path d="M152.99,87.58C152.99,87.58,152.99,87.58,152.99,87.58c-0.01,0.02-0.02,0.04-0.03,0.06L152.99,87.58z" />
						<path d="M151.27,88.01c0.12-0.28,0.23-0.56,0.34-0.84c-1.47-0.44-3.6-1.05-3.62-1C147.98,86.22,149.92,87.28,151.27,88.01z" />
						<path d="M145.9,98.32l0.27-0.27C146.08,98.14,145.99,98.22,145.9,98.32C145.9,98.31,145.9,98.32,145.9,98.32z" />
						<path d="M144.99,97.19c0.07-0.07,0.13-0.13,0.2-0.2c0.15-0.15,0.29-0.3,0.44-0.45c-1.19-0.96-2.91-2.34-2.95-2.3
				C142.63,94.29,144.02,96,144.99,97.19z"/>
						<path d="M146.75,97.47C146.75,97.47,146.75,97.46,146.75,97.47c-0.17,0.17-0.33,0.34-0.5,0.5L146.75,97.47z" />
						<path d="M135.68,103.3c0.28-0.11,0.56-0.23,0.84-0.35c-0.73-1.35-1.8-3.29-1.85-3.27C134.61,99.71,135.24,101.83,135.68,103.3z"
						/>
						<path d="M137.2,104.21C137.2,104.21,137.2,104.21,137.2,104.21c-0.03,0.01-0.06,0.03-0.1,0.04L137.2,104.21z" />
						<path d="M125.79,106.8C125.79,106.8,125.79,106.79,125.79,106.8c-0.23,0-0.46,0-0.69,0L125.79,106.8z" />
						<path d="M124.59,106.8l0.34,0C124.82,106.8,124.71,106.79,124.59,106.8C124.59,106.79,124.59,106.8,124.59,106.8z" />
						<path d="M124.74,105.36c0.09,0,0.17,0,0.26,0c0.21,0,0.43,0,0.64-0.01c-0.16-1.53-0.41-3.71-0.46-3.71
				C125.12,101.64,124.89,103.83,124.74,105.36z"/>
						<path d="M114.27,104.82C114.27,104.82,114.27,104.82,114.27,104.82c-0.01-0.01-0.03-0.01-0.04-0.02L114.27,104.82z" />
						<path d="M113.84,103.1c0.28,0.12,0.56,0.23,0.84,0.34c0.43-1.47,1.05-3.6,1-3.62S114.56,101.75,113.84,103.1z" />
						<path d="M103.52,97.74l0.21,0.21C103.66,97.88,103.59,97.81,103.52,97.74C103.53,97.74,103.52,97.74,103.52,97.74z" />
						<path d="M104.64,96.82c0.06,0.06,0.11,0.12,0.17,0.17c0.16,0.16,0.31,0.31,0.47,0.46c0.96-1.19,2.34-2.91,2.3-2.95
				C107.54,94.47,105.83,95.85,104.64,96.82z"/>
						<path d="M104.37,98.59C104.37,98.59,104.38,98.58,104.37,98.59c-0.15-0.16-0.31-0.31-0.47-0.46L104.37,98.59z" />
						<path d="M97.61,89.05C97.61,89.05,97.61,89.04,97.61,89.05c-0.01-0.01-0.01-0.03-0.02-0.04L97.61,89.05z" />
						<path d="M98.52,87.52c0.11,0.28,0.23,0.56,0.35,0.84c1.35-0.74,3.29-1.81,3.27-1.86C102.12,86.45,99.99,87.08,98.52,87.52z" />
						<path d="M95.02,77.64C95.02,77.64,95.02,77.64,95.02,77.64c0-0.22,0-0.45,0-0.67L95.02,77.64z" />
						<path d="M96.46,76.59c0,0.08,0,0.15,0,0.23c0,0.23,0,0.45,0.01,0.68c1.53-0.16,3.71-0.41,3.71-0.47
				C100.17,76.97,97.98,76.74,96.46,76.59z"/>
						<path d="M95.01,76.44l0,0.28C95.01,76.63,95.02,76.54,95.01,76.44C95.02,76.44,95.01,76.44,95.01,76.44z" />
						<path d="M98.7,65.68c-0.12,0.28-0.23,0.56-0.34,0.84c1.47,0.43,3.6,1.05,3.62,0.99C102,67.46,100.05,66.41,98.7,65.68z" />
						<path d="M96.98,66.11C96.98,66.11,96.98,66.12,96.98,66.11c0.01-0.01,0.01-0.03,0.02-0.05L96.98,66.11z" />
						<path d="M104.96,56.48c-0.05,0.05-0.1,0.1-0.15,0.15c-0.16,0.16-0.33,0.33-0.49,0.5c1.2,0.96,2.92,2.33,2.96,2.29
				C107.32,59.38,105.94,57.67,104.96,56.48z"/>
						<path d="M104.04,55.36l-0.2,0.2C103.91,55.49,103.98,55.43,104.04,55.36C104.05,55.36,104.04,55.36,104.04,55.36z" />
						<path d="M103.2,56.21C103.2,56.21,103.2,56.22,103.2,56.21c0.16-0.16,0.33-0.33,0.49-0.49L103.2,56.21z" />
						<path d="M112.73,49.44L112.73,49.44c0.01-0.01,0.03-0.01,0.04-0.02L112.73,49.44z" />
						<path d="M114.26,50.35c-0.28,0.11-0.56,0.23-0.84,0.35c0.74,1.35,1.81,3.28,1.86,3.26C115.33,53.94,114.7,51.82,114.26,50.35z" />
						<path d="M154.99,76.81c0-0.02,0-0.03,0-0.05c0-0.24,0-0.47-0.01-0.71c-0.04-1.61-0.21-3.19-0.5-4.73l0,0l-0.02-0.11
				c-0.06-0.29-0.11-0.59-0.18-0.88c-0.01-0.06-0.03-0.13-0.04-0.19c-0.34-1.51-0.81-2.98-1.37-4.39c0,0,0,0,0,0l-0.41-0.99
				c-0.02-0.04-0.03-0.08-0.05-0.11c-0.65-1.47-1.42-2.88-2.3-4.22c-0.03-0.04-0.06-0.09-0.09-0.13c-0.11-0.16-0.22-0.33-0.33-0.49
				c-0.09-0.12-0.17-0.25-0.26-0.37c-0.88-1.24-1.86-2.41-2.92-3.5c-0.1-0.1-0.19-0.2-0.29-0.29c-0.02-0.02-0.05-0.05-0.07-0.07
				c-0.16-0.16-0.32-0.32-0.49-0.48c-1.16-1.1-2.4-2.11-3.72-3.01c-0.03-0.02-0.06-0.04-0.09-0.06c-0.24-0.16-0.48-0.33-0.73-0.48
				c-0.06-0.04-0.13-0.08-0.19-0.12c-0.26-0.16-0.52-0.32-0.78-0.47c-1.05-0.62-2.15-1.15-3.28-1.64c0-0.01,0.01-0.02,0.01-0.02
				l-1.06-0.44c-0.02-0.01-0.03-0.01-0.05-0.02c-1.49-0.57-3.03-1.03-4.63-1.37l0,0l-0.02,0c-0.32-0.07-0.64-0.13-0.97-0.19
				c-0.06-0.01-0.13-0.02-0.19-0.03c-1.47-0.25-2.98-0.39-4.52-0.41c-0.13,0-0.27,0-0.4-0.01c-0.03,0-0.05,0-0.08,0
				c-0.03,0-0.05,0-0.08,0c-0.21,0-0.43,0-0.64,0.01c-1.63,0.04-3.23,0.21-4.79,0.5l0,0l-0.08,0.02c-0.32,0.06-0.64,0.12-0.95,0.19
				c-0.05,0.01-0.09,0.02-0.14,0.03c-1.54,0.35-3.04,0.83-4.48,1.4l0,0l-1.06,0.45c-0.01,0.01-0.03,0.01-0.04,0.02
				c-1,0.45-1.97,0.95-2.91,1.5c-0.41,0.24-0.81,0.49-1.21,0.75c-0.02,0.02-0.05,0.03-0.07,0.05c-0.2,0.13-0.4,0.27-0.6,0.41
				c-0.11,0.07-0.21,0.15-0.32,0.22c-1.27,0.9-2.47,1.91-3.58,2.99c-0.07,0.06-0.13,0.13-0.2,0.19c-0.05,0.05-0.11,0.11-0.16,0.16
				c-0.16,0.16-0.32,0.33-0.48,0.5c-1.08,1.14-2.06,2.36-2.95,3.66c-0.01,0.02-0.02,0.03-0.03,0.05c-0.18,0.26-0.35,0.52-0.52,0.78
				c-0.04,0.06-0.07,0.11-0.1,0.17c-0.82,1.31-1.54,2.69-2.16,4.13c0,0,0,0,0,0L97,66.07c-0.01,0.02-0.01,0.03-0.02,0.05
				c-0.56,1.46-1,2.97-1.32,4.52l0,0l-0.2,1.02c-0.01,0.05-0.02,0.11-0.03,0.16c-0.25,1.51-0.39,3.05-0.41,4.63
				c0,0.09,0,0.19,0,0.28c0,0.03,0,0.06,0,0.09c0,0.05,0,0.11,0,0.16c0,0.22,0,0.45,0.01,0.67c0.04,1.57,0.21,3.11,0.49,4.62l0,0
				l0.01,0.06c0.06,0.33,0.13,0.66,0.2,0.98c0.01,0.04,0.02,0.09,0.03,0.13c0.35,1.55,0.82,3.05,1.4,4.5c0,0,0,0,0,0L97.6,89
				c0.01,0.01,0.01,0.03,0.02,0.04c0.65,1.45,1.41,2.84,2.27,4.15c0.01,0.02,0.02,0.04,0.04,0.05c0.14,0.22,0.29,0.43,0.44,0.64
				c0.07,0.1,0.13,0.2,0.2,0.29c0.9,1.26,1.89,2.45,2.97,3.55c0.07,0.07,0.14,0.14,0.21,0.21c0.06,0.06,0.12,0.12,0.17,0.17
				c0.16,0.15,0.31,0.31,0.47,0.46c1.13,1.07,2.34,2.05,3.63,2.94c0.02,0.02,0.05,0.03,0.07,0.05c0.26,0.18,0.52,0.35,0.78,0.52
				c0.05,0.03,0.1,0.06,0.15,0.09c1.32,0.83,2.71,1.56,4.15,2.18c0,0,0,0,0,0l1.07,0.44c0.01,0.01,0.03,0.01,0.04,0.02
				c1.47,0.56,2.99,1.01,4.56,1.34l0,0c0.33,0.07,0.67,0.14,1,0.19c0.06,0.01,0.12,0.02,0.18,0.03c1.5,0.25,3.03,0.39,4.59,0.41
				c0.11,0,0.22,0,0.34,0c0.02,0,0.05,0,0.07,0c0.03,0,0.07,0,0.1,0c0.23,0,0.46,0,0.69-0.01c1.59-0.04,3.15-0.21,4.67-0.49l0,0
				l0.09-0.02c0.31-0.06,0.63-0.12,0.94-0.19c0.05-0.01,0.1-0.02,0.15-0.03c1.53-0.35,3.02-0.82,4.45-1.39c0,0,0,0,0,0l1.01-0.42
				c0.03-0.01,0.06-0.03,0.1-0.04c1.46-0.65,2.86-1.42,4.19-2.28c0.04-0.02,0.07-0.05,0.11-0.07c0.18-0.12,0.36-0.24,0.53-0.36
				c0.12-0.08,0.23-0.16,0.35-0.24c1.25-0.89,2.43-1.88,3.52-2.94c0.09-0.09,0.18-0.17,0.27-0.26c0.03-0.03,0.05-0.05,0.08-0.08
				c0.17-0.17,0.33-0.34,0.49-0.51c1.09-1.14,2.08-2.37,2.98-3.67c0.02-0.03,0.04-0.06,0.07-0.1c0.16-0.24,0.32-0.48,0.48-0.72
				c0.04-0.06,0.08-0.12,0.12-0.19c0.82-1.3,1.54-2.67,2.15-4.1c0,0,0,0,0,0l0.43-1.05c0.01-0.02,0.02-0.04,0.02-0.06
				c0.57-1.48,1.02-3.01,1.35-4.59l0,0l0.01-0.03c0.07-0.31,0.13-0.63,0.18-0.95c0.01-0.07,0.02-0.14,0.03-0.2
				c0.25-1.48,0.39-3,0.41-4.55c0-0.13,0-0.27,0.01-0.4C154.99,76.84,154.99,76.83,154.99,76.81z M152.1,77.09
				c-0.01,1.45-0.14,2.89-0.38,4.29c-0.05,0.32-0.11,0.63-0.18,0.94c-0.3,1.48-0.73,2.92-1.27,4.32c-0.1,0.27-0.21,0.53-0.33,0.8
				c-0.57,1.34-1.25,2.63-2.03,3.86c-0.17,0.27-0.35,0.54-0.53,0.8c-0.83,1.21-1.77,2.37-2.81,3.45c-0.14,0.14-0.27,0.29-0.42,0.43
				c-0.06,0.06-0.13,0.12-0.19,0.19c-1.04,1.02-2.15,1.95-3.31,2.78c-0.26,0.19-0.53,0.37-0.79,0.54c-1.26,0.83-2.57,1.54-3.94,2.15
				c-0.26,0.12-0.53,0.23-0.8,0.33c-1.36,0.55-2.75,0.98-4.19,1.3c-0.31,0.07-0.63,0.13-0.94,0.19c-1.44,0.27-2.91,0.42-4.4,0.45
				c-0.2,0-0.41,0.01-0.61,0.01c-0.08,0-0.17,0-0.25,0c-1.46-0.01-2.91-0.14-4.33-0.38c-0.32-0.05-0.63-0.11-0.94-0.18
				c-1.47-0.3-2.9-0.72-4.29-1.26c-0.27-0.1-0.53-0.21-0.8-0.33c-1.35-0.57-2.66-1.26-3.91-2.05c-0.27-0.17-0.54-0.35-0.8-0.53
				c-1.2-0.82-2.34-1.75-3.41-2.77c-0.15-0.14-0.3-0.29-0.45-0.44c-0.05-0.05-0.11-0.11-0.16-0.17c-1.03-1.05-1.97-2.16-2.8-3.34
				c-0.19-0.26-0.37-0.53-0.54-0.79c-0.82-1.25-1.53-2.55-2.13-3.91c-0.12-0.26-0.23-0.53-0.33-0.8c-0.55-1.37-1-2.79-1.32-4.24
				c-0.07-0.31-0.13-0.63-0.19-0.94c-0.26-1.42-0.42-2.87-0.45-4.35c0-0.21-0.01-0.43-0.01-0.64c0-0.07,0-0.14,0-0.22
				c0.01-1.47,0.14-2.93,0.38-4.36c0.05-0.32,0.11-0.63,0.18-0.94c0.3-1.46,0.72-2.88,1.25-4.26c0.1-0.27,0.21-0.53,0.33-0.8
				c0.57-1.35,1.25-2.65,2.03-3.89c0.17-0.27,0.35-0.54,0.53-0.8c0.83-1.21,1.75-2.36,2.78-3.44c0.15-0.16,0.31-0.32,0.46-0.47
				c0.05-0.05,0.1-0.09,0.14-0.14c1.05-1.04,2.18-1.98,3.36-2.82c0.26-0.19,0.53-0.37,0.79-0.54c1.24-0.81,2.53-1.52,3.88-2.12
				c0.26-0.12,0.53-0.23,0.79-0.34c0.98-0.4,1.98-0.74,3.01-1.02c0.42-0.05,0.83-0.1,1.25-0.14l-0.04-0.16
				c0.31-0.07,0.63-0.13,0.94-0.19l0.04,0.28c0.97-0.07,1.94-0.11,2.9-0.11h1.63c-0.02-0.22-0.05-0.43-0.07-0.64
				c0.18,0,0.36-0.01,0.54-0.01c0.11,0,0.21,0,0.32,0c-0.02,0.2-0.04,0.42-0.07,0.64h1.86c0.81,0,1.62,0.02,2.43,0.07l0.04-0.33
				c0.32,0.05,0.63,0.11,0.94,0.18l-0.06,0.22c0.58,0.05,1.16,0.11,1.74,0.18c0.91,0.25,1.8,0.54,2.67,0.88
				c0.27,0.1,0.53,0.21,0.8,0.33c1.32,0.56,2.59,1.23,3.81,2.01c0.27,0.17,0.54,0.35,0.8,0.53c1.23,0.84,2.4,1.79,3.49,2.84
				c0.13,0.13,0.27,0.26,0.4,0.39c0.07,0.07,0.14,0.14,0.21,0.21c1.01,1.03,1.93,2.13,2.75,3.29c0.19,0.26,0.37,0.53,0.54,0.79
				c0.83,1.26,1.56,2.59,2.16,3.97c0.12,0.26,0.23,0.53,0.33,0.8c0.54,1.34,0.97,2.72,1.29,4.14c0.07,0.31,0.13,0.63,0.19,0.94
				c0.27,1.46,0.43,2.94,0.46,4.45c0,0.19,0.01,0.38,0.01,0.58C152.11,76.91,152.1,77,152.1,77.09z"/>
					</g>
				</g>
			</g>
		</svg>

	);
};


