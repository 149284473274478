import React from "react";
import style from "./logoTypo.module.styl"

export default ({ className }) => {
	return (
		<svg className={style.root} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			width="440.74px" height="40px" viewBox="0 0 441 40"
		>
			<defs>
			</defs>
			<g>
				<path d="M8.93,25.79c0,1.05,0.06,1.9,0.19,2.55c0.12,0.65,0.39,1.15,0.81,1.5c0.41,0.35,1,0.59,1.77,0.73
		c0.76,0.14,1.77,0.22,3.01,0.25v1.58H0v-1.58c0.97-0.03,1.75-0.11,2.35-0.25c0.6-0.14,1.06-0.37,1.39-0.71
		c0.33-0.33,0.55-0.8,0.66-1.41c0.11-0.61,0.17-1.4,0.17-2.37V6.73c0-0.97-0.05-1.75-0.15-2.35c-0.1-0.59-0.29-1.07-0.58-1.41
		C3.55,2.62,3.13,2.39,2.58,2.26C2.02,2.14,1.3,2.05,0.42,1.99V0.42h24.01l0.46,7.93h-1.45c-0.17-1.19-0.43-2.17-0.79-2.93
		c-0.36-0.76-0.87-1.36-1.52-1.81c-0.65-0.44-1.47-0.75-2.47-0.93c-1-0.18-2.24-0.27-3.74-0.27H9.64c-0.47,0-0.71,0.24-0.71,0.71
		v12.54c1.33,0,2.47-0.08,3.43-0.23c0.95-0.15,1.74-0.42,2.37-0.81c0.62-0.39,1.1-0.91,1.43-1.58c0.33-0.66,0.54-1.52,0.62-2.57
		h1.45v12.17h-1.45c-0.14-1.05-0.36-1.92-0.66-2.62c-0.31-0.69-0.75-1.23-1.35-1.62c-0.6-0.39-1.36-0.66-2.31-0.83
		c-0.94-0.17-2.12-0.25-3.53-0.25V25.79z"/>
				<path d="M45.52,20.81H34.43c-0.47,1.14-0.87,2.14-1.2,3.01c-0.33,0.87-0.6,1.63-0.81,2.26c-0.21,0.64-0.35,1.18-0.44,1.62
		c-0.08,0.44-0.12,0.8-0.12,1.08c0,0.78,0.25,1.31,0.75,1.6c0.5,0.29,1.33,0.44,2.49,0.44v1.58H25v-1.58
		c0.64-0.08,1.18-0.19,1.64-0.33c0.46-0.14,0.86-0.33,1.2-0.58c0.35-0.25,0.65-0.57,0.91-0.98c0.26-0.4,0.52-0.89,0.77-1.47
		l10.8-27.04h1.79l9.43,23.42c0.64,1.55,1.19,2.79,1.66,3.72c0.47,0.93,0.93,1.63,1.37,2.1c0.44,0.47,0.92,0.78,1.43,0.91
		c0.51,0.14,1.13,0.21,1.85,0.21v1.62H44.32v-1.62c1.47,0,2.48-0.14,3.03-0.41c0.55-0.28,0.83-0.73,0.83-1.37
		c0-0.28-0.01-0.53-0.04-0.77c-0.03-0.23-0.08-0.49-0.15-0.77c-0.07-0.28-0.17-0.59-0.29-0.93c-0.12-0.35-0.28-0.75-0.48-1.23
		L45.52,20.81z M35.26,18.73h9.47L40.12,6.48L35.26,18.73z"/>
				<path d="M88.22,32.81l-19.73-26h-0.17v17.73c0,1.39,0.05,2.48,0.15,3.28c0.1,0.8,0.29,1.43,0.58,1.87
		c0.29,0.44,0.71,0.73,1.25,0.87c0.54,0.14,1.25,0.22,2.14,0.25v1.58H61.26v-1.58c0.97-0.03,1.75-0.12,2.35-0.27
		c0.6-0.15,1.06-0.44,1.39-0.85c0.33-0.41,0.55-1,0.66-1.77c0.11-0.76,0.17-1.79,0.17-3.09V7.97c0-1.16-0.05-2.12-0.15-2.89
		c-0.1-0.76-0.29-1.36-0.58-1.81c-0.29-0.44-0.71-0.75-1.27-0.93c-0.55-0.18-1.27-0.3-2.16-0.35V0.42h8.22l18.15,24.21h0.17V8.27
		c0-1.36-0.05-2.44-0.15-3.26c-0.1-0.82-0.29-1.45-0.58-1.89c-0.29-0.44-0.71-0.73-1.25-0.87C85.7,2.1,84.99,2.02,84.1,1.99V0.42
		h11.17v1.58c-0.97,0.03-1.75,0.12-2.35,0.27c-0.6,0.15-1.06,0.44-1.39,0.85c-0.33,0.41-0.55,1.01-0.66,1.79
		c-0.11,0.78-0.17,1.8-0.17,3.07v24.84H88.22z"/>
				<path d="M131.57,8.35h-1.7c-0.08-1.19-0.28-2.19-0.58-2.99c-0.3-0.8-0.74-1.43-1.31-1.89c-0.57-0.46-1.28-0.78-2.14-0.98
		c-0.86-0.19-1.88-0.29-3.07-0.29c-1.02,0-1.85,0.02-2.47,0.06c-0.62,0.04-1.1,0.12-1.43,0.23c-0.33,0.11-0.55,0.24-0.64,0.39
		c-0.1,0.15-0.15,0.35-0.15,0.6v22.22c0,0.89,0.06,1.65,0.19,2.28c0.12,0.64,0.37,1.16,0.75,1.56c0.37,0.4,0.91,0.71,1.62,0.91
		c0.71,0.21,1.64,0.33,2.8,0.35v1.58h-15.7v-1.58c1.16-0.03,2.1-0.15,2.82-0.35c0.72-0.21,1.27-0.51,1.64-0.91
		c0.37-0.4,0.62-0.92,0.75-1.56c0.13-0.64,0.19-1.4,0.19-2.28V3.49c0-0.25-0.05-0.45-0.15-0.6c-0.1-0.15-0.29-0.28-0.58-0.39
		c-0.29-0.11-0.7-0.19-1.23-0.23c-0.53-0.04-1.23-0.06-2.12-0.06c-1.08,0-2.08,0.04-3.01,0.12c-0.93,0.08-1.73,0.33-2.41,0.73
		c-0.68,0.4-1.23,1.02-1.64,1.85c-0.42,0.83-0.64,1.98-0.66,3.45H99.8V0.42h31.36L131.57,8.35z"/>
				<path d="M151.59,20.81H140.5c-0.47,1.14-0.87,2.14-1.2,3.01c-0.33,0.87-0.6,1.63-0.81,2.26c-0.21,0.64-0.35,1.18-0.44,1.62
		c-0.08,0.44-0.12,0.8-0.12,1.08c0,0.78,0.25,1.31,0.75,1.6c0.5,0.29,1.33,0.44,2.49,0.44v1.58h-10.09v-1.58
		c0.64-0.08,1.18-0.19,1.64-0.33c0.46-0.14,0.86-0.33,1.2-0.58c0.35-0.25,0.65-0.57,0.91-0.98c0.26-0.4,0.52-0.89,0.77-1.47
		l10.8-27.04h1.79l9.43,23.42c0.64,1.55,1.19,2.79,1.66,3.72c0.47,0.93,0.93,1.63,1.37,2.1c0.44,0.47,0.92,0.78,1.43,0.91
		c0.51,0.14,1.13,0.21,1.85,0.21v1.62h-13.54v-1.62c1.47,0,2.48-0.14,3.03-0.41c0.55-0.28,0.83-0.73,0.83-1.37
		c0-0.28-0.01-0.53-0.04-0.77c-0.03-0.23-0.08-0.49-0.15-0.77c-0.07-0.28-0.17-0.59-0.29-0.93c-0.12-0.35-0.28-0.75-0.48-1.23
		L151.59,20.81z M141.33,18.73h9.47l-4.61-12.25L141.33,18.73z"/>
				<path d="M167.87,22.64h1.54c0.19,1.25,0.56,2.37,1.1,3.36c0.54,1,1.22,1.84,2.04,2.53c0.82,0.69,1.76,1.23,2.82,1.6
		c1.07,0.37,2.22,0.56,3.47,0.56c2.05,0,3.67-0.48,4.86-1.45c1.19-0.97,1.79-2.28,1.79-3.95c0-0.69-0.12-1.33-0.35-1.91
		c-0.24-0.58-0.63-1.14-1.18-1.68c-0.55-0.54-1.29-1.07-2.22-1.6c-0.93-0.53-2.1-1.09-3.51-1.7c-1.72-0.75-3.16-1.47-4.32-2.16
		c-1.16-0.69-2.09-1.41-2.78-2.16c-0.69-0.75-1.18-1.54-1.47-2.39c-0.29-0.84-0.44-1.81-0.44-2.89c0-1.27,0.23-2.45,0.69-3.53
		c0.46-1.08,1.1-2.01,1.93-2.8c0.83-0.79,1.81-1.4,2.95-1.83C175.9,0.22,177.15,0,178.51,0c1.22,0,2.35,0.19,3.41,0.58
		c1.05,0.39,2.12,1.01,3.2,1.87l1.08-1.87h0.95l0.46,8.26h-1.54c-0.66-2.27-1.63-3.96-2.89-5.07c-1.26-1.11-2.78-1.66-4.55-1.66
		c-1.69,0-3.03,0.42-4.03,1.25c-1,0.83-1.49,1.95-1.49,3.36c0,0.66,0.11,1.27,0.33,1.81c0.22,0.54,0.58,1.05,1.08,1.54
		c0.5,0.48,1.15,0.97,1.95,1.45c0.8,0.48,1.8,0.99,2.99,1.52c2.05,0.91,3.75,1.77,5.11,2.55c1.36,0.79,2.44,1.58,3.24,2.37
		c0.8,0.79,1.37,1.62,1.7,2.49c0.33,0.87,0.5,1.85,0.5,2.93c0,1.38-0.28,2.66-0.83,3.82c-0.55,1.16-1.33,2.16-2.33,2.99
		c-1,0.83-2.19,1.47-3.57,1.93c-1.38,0.46-2.91,0.68-4.57,0.68c-1.58,0-3-0.22-4.26-0.66c-1.26-0.44-2.54-1.19-3.84-2.24l-1.16,2.03
		h-0.95L167.87,22.64z"/>
				<path d="M217.17,24.96l10.8-24.55h9.3v1.58c-0.89,0.03-1.6,0.14-2.14,0.33c-0.54,0.19-0.96,0.52-1.25,0.98
		c-0.29,0.46-0.48,1.09-0.58,1.89c-0.1,0.8-0.15,1.83-0.15,3.07v16.28c0,1.39,0.06,2.48,0.17,3.28c0.11,0.8,0.33,1.43,0.64,1.87
		c0.32,0.44,0.77,0.73,1.37,0.87c0.59,0.14,1.38,0.22,2.35,0.25v1.58h-13.46v-1.58c0.97-0.03,1.75-0.12,2.35-0.27
		c0.59-0.15,1.06-0.44,1.39-0.85c0.33-0.41,0.55-1,0.66-1.77c0.11-0.76,0.17-1.79,0.17-3.09V5.57h-0.42l-12.13,27.24h-0.91
		L203.42,5.57h-0.17v18.98c0,1.39,0.06,2.48,0.17,3.28c0.11,0.8,0.33,1.43,0.64,1.87c0.32,0.44,0.77,0.73,1.37,0.87
		c0.6,0.14,1.38,0.22,2.35,0.25v1.58H196.2v-1.58c0.97-0.03,1.75-0.12,2.35-0.27c0.6-0.15,1.06-0.44,1.39-0.85
		c0.33-0.41,0.55-1,0.66-1.77c0.11-0.76,0.17-1.79,0.17-3.09V7.97c0-1.16-0.05-2.12-0.15-2.89c-0.1-0.76-0.29-1.36-0.58-1.81
		c-0.29-0.44-0.71-0.75-1.27-0.93c-0.55-0.18-1.27-0.3-2.16-0.35V0.42h9.93l10.47,24.55H217.17z"/>
				<path d="M261.94,20.81h-11.09c-0.47,1.14-0.87,2.14-1.2,3.01c-0.33,0.87-0.6,1.63-0.81,2.26c-0.21,0.64-0.35,1.18-0.44,1.62
		c-0.08,0.44-0.12,0.8-0.12,1.08c0,0.78,0.25,1.31,0.75,1.6c0.5,0.29,1.33,0.44,2.49,0.44v1.58h-10.09v-1.58
		c0.64-0.08,1.18-0.19,1.64-0.33c0.46-0.14,0.86-0.33,1.2-0.58c0.35-0.25,0.65-0.57,0.91-0.98c0.26-0.4,0.52-0.89,0.77-1.47
		l10.8-27.04h1.79l9.43,23.42c0.64,1.55,1.19,2.79,1.66,3.72c0.47,0.93,0.93,1.63,1.37,2.1c0.44,0.47,0.92,0.78,1.43,0.91
		c0.51,0.14,1.13,0.21,1.85,0.21v1.62h-13.54v-1.62c1.47,0,2.48-0.14,3.03-0.41c0.55-0.28,0.83-0.73,0.83-1.37
		c0-0.28-0.01-0.53-0.04-0.77c-0.03-0.23-0.08-0.49-0.15-0.77c-0.07-0.28-0.17-0.59-0.29-0.93c-0.13-0.35-0.28-0.75-0.48-1.23
		L261.94,20.81z M251.69,18.73h9.47l-4.61-12.25L251.69,18.73z"/>
				<path d="M292.76,32.81c-2.35,0-4.47-0.37-6.35-1.12c-1.88-0.75-3.49-1.81-4.82-3.18c-1.33-1.37-2.35-3.04-3.05-5
		c-0.71-1.97-1.06-4.15-1.06-6.56c0-2.52,0.39-4.82,1.16-6.89c0.78-2.08,1.86-3.86,3.26-5.36c1.4-1.5,3.07-2.65,5.03-3.47
		c1.95-0.82,4.1-1.23,6.46-1.23c1.66,0,3.23,0.24,4.69,0.71c1.47,0.47,3.06,1.23,4.78,2.28l0.87-2.58h1.45l-0.54,9.55h-1.99
		c0-1.36-0.26-2.53-0.77-3.51c-0.51-0.98-1.18-1.79-2.01-2.43c-0.83-0.64-1.78-1.11-2.84-1.41c-1.07-0.3-2.15-0.46-3.26-0.46
		c-1.69,0-3.2,0.33-4.53,1c-1.33,0.66-2.45,1.62-3.36,2.87c-0.91,1.25-1.61,2.77-2.1,4.57c-0.48,1.8-0.73,3.83-0.73,6.11
		c0,4.46,0.93,7.88,2.78,10.28c1.85,2.4,4.54,3.59,8.06,3.59c1.33,0,2.52-0.16,3.57-0.48c1.05-0.32,1.94-0.75,2.68-1.29
		c0.73-0.54,1.29-1.16,1.66-1.87c0.37-0.71,0.56-1.45,0.56-2.22c0-1.61-0.33-2.69-0.98-3.26c-0.65-0.57-1.71-0.85-3.18-0.85v-1.58
		H310v1.58c-0.67,0-1.22,0.06-1.66,0.17c-0.44,0.11-0.8,0.31-1.06,0.6c-0.26,0.29-0.44,0.67-0.54,1.14
		c-0.1,0.47-0.15,1.07-0.15,1.79v4.65c-0.53,0-1,0.02-1.43,0.06c-0.43,0.04-0.88,0.14-1.35,0.29c-0.47,0.15-0.99,0.36-1.56,0.62
		c-0.57,0.26-1.24,0.62-2.01,1.06c-0.97,0.55-2.08,1-3.34,1.33C295.63,32.64,294.26,32.81,292.76,32.81"/>
				<path d="M332.84,0c2.44,0,4.71,0.43,6.81,1.29c2.1,0.86,3.93,2.03,5.48,3.53c1.55,1.49,2.77,3.25,3.65,5.27
		c0.89,2.02,1.33,4.21,1.33,6.56c0,2.24-0.45,4.35-1.35,6.31c-0.9,1.97-2.13,3.68-3.68,5.13c-1.55,1.45-3.38,2.6-5.48,3.45
		c-2.1,0.84-4.36,1.27-6.77,1.27c-2.41,0-4.67-0.42-6.77-1.27c-2.1-0.84-3.93-1.99-5.48-3.45c-1.55-1.45-2.78-3.16-3.68-5.13
		c-0.9-1.97-1.35-4.07-1.35-6.31c0-2.35,0.44-4.54,1.33-6.56c0.89-2.02,2.1-3.78,3.66-5.27c1.55-1.5,3.38-2.67,5.48-3.53
		C328.13,0.43,330.4,0,332.84,0 M332.84,30.53c1.83,0,3.47-0.32,4.92-0.96c1.45-0.64,2.7-1.55,3.74-2.74
		c1.04-1.19,1.83-2.64,2.39-4.34c0.55-1.7,0.83-3.61,0.83-5.71c0-2.21-0.28-4.22-0.83-6c-0.55-1.79-1.34-3.31-2.37-4.57
		c-1.02-1.26-2.27-2.23-3.74-2.91c-1.47-0.68-3.11-1.02-4.94-1.02c-1.83,0-3.48,0.34-4.94,1.02c-1.47,0.68-2.71,1.65-3.74,2.91
		c-1.02,1.26-1.81,2.78-2.37,4.57c-0.55,1.79-0.83,3.79-0.83,6c0,2.1,0.28,4.01,0.83,5.71c0.55,1.7,1.34,3.15,2.37,4.34
		c1.02,1.19,2.27,2.1,3.74,2.74C329.36,30.21,331.01,30.53,332.84,30.53"/>
				<path d="M381.35,8.43c0,2.22-0.67,4.06-1.99,5.52c-1.33,1.47-3.45,2.45-6.35,2.95c0.67,0.92,1.34,1.84,2.01,2.75
		c1.3,1.77,2.59,3.55,3.89,5.32c2.13,2.92,4.03,5.98,6.03,8.99c1.05,1.57,2.25,3.02,4.06,3.79c0.22,0.1,0.45,0.2,0.7,0.2
		c0,0.41,0,1.88,0,2.04c-0.41,0-1.51-0.52-1.62-0.58c-0.63-0.32-1.24-0.69-1.82-1.08c-2.33-1.59-4.28-3.65-6.06-5.83
		c-1.83-2.24-3.71-4.44-5.49-6.72c-1.67-2.15-3.35-4.29-5.02-6.43c-0.36-0.47-0.7-0.84-1.02-1.12c-0.32-0.28-0.65-0.49-1-0.64
		c-0.35-0.15-0.75-0.25-1.2-0.29c-0.46-0.04-1-0.06-1.64-0.06v8.64c0,1.02,0.06,1.86,0.19,2.51c0.12,0.65,0.39,1.16,0.79,1.52
		c0.4,0.36,0.98,0.6,1.75,0.73c0.76,0.12,1.77,0.19,3.01,0.19v1.58h-14.66v-1.58c0.97-0.03,1.75-0.11,2.35-0.25
		c0.6-0.14,1.06-0.37,1.39-0.71c0.33-0.33,0.55-0.8,0.66-1.41c0.11-0.61,0.17-1.4,0.17-2.37V6.73c0-0.97-0.05-1.75-0.15-2.35
		c-0.1-0.6-0.29-1.07-0.58-1.41c-0.29-0.35-0.71-0.58-1.27-0.71c-0.55-0.12-1.27-0.21-2.16-0.27V0.42h13.04
		c3.82,0,6.78,0.71,8.87,2.12C380.3,3.95,381.35,5.91,381.35,8.43 M364.82,15.16h2.95c2.77,0,4.82-0.5,6.17-1.52
		c1.34-1.01,2.01-2.68,2.01-5c0-2.24-0.53-3.85-1.6-4.82c-1.07-0.97-2.73-1.45-5-1.45h-3.82c-0.47,0-0.71,0.24-0.71,0.71V15.16z"/>
				<path d="M399.62,24.55c0,1.39,0.06,2.48,0.17,3.28c0.11,0.8,0.32,1.43,0.64,1.87c0.32,0.44,0.77,0.73,1.37,0.87
		c0.6,0.14,1.38,0.22,2.35,0.25v1.58h-13.46v-1.58c0.97-0.03,1.75-0.12,2.35-0.27c0.6-0.15,1.06-0.44,1.39-0.85
		c0.33-0.41,0.55-1,0.66-1.77c0.11-0.76,0.17-1.79,0.17-3.09V7.97c0-1.16-0.05-2.12-0.15-2.89c-0.1-0.76-0.29-1.36-0.58-1.81
		c-0.29-0.44-0.71-0.75-1.27-0.93c-0.55-0.18-1.27-0.3-2.16-0.35V0.42h12.63v1.58c-0.89,0.03-1.6,0.14-2.14,0.33
		c-0.54,0.19-0.96,0.52-1.25,0.98c-0.29,0.46-0.48,1.09-0.58,1.89c-0.1,0.8-0.15,1.83-0.15,3.07V24.55z"/>
				<path d="M428.41,20.81h-11.09c-0.47,1.14-0.87,2.14-1.2,3.01c-0.33,0.87-0.6,1.63-0.81,2.26c-0.21,0.64-0.35,1.18-0.44,1.62
		c-0.08,0.44-0.12,0.8-0.12,1.08c0,0.78,0.25,1.31,0.75,1.6c0.5,0.29,1.33,0.44,2.49,0.44v1.58h-10.09v-1.58
		c0.64-0.08,1.18-0.19,1.64-0.33c0.46-0.14,0.86-0.33,1.2-0.58c0.35-0.25,0.65-0.57,0.91-0.98c0.26-0.4,0.52-0.89,0.77-1.47
		l10.8-27.04H425l9.43,23.42c0.64,1.55,1.19,2.79,1.66,3.72c0.47,0.93,0.93,1.63,1.37,2.1c0.44,0.47,0.92,0.78,1.43,0.91
		c0.51,0.14,1.13,0.21,1.85,0.21v1.62H427.2v-1.62c1.47,0,2.48-0.14,3.03-0.41c0.55-0.28,0.83-0.73,0.83-1.37
		c0-0.28-0.01-0.53-0.04-0.77c-0.03-0.23-0.08-0.49-0.14-0.77c-0.07-0.28-0.17-0.59-0.29-0.93c-0.12-0.35-0.28-0.75-0.48-1.23
		L428.41,20.81z M418.15,18.73h9.47l-4.61-12.25L418.15,18.73z"/>
			</g>
		</svg>
	);
};


