import React, { useContext } from "react";
import style from "./drawerMenu.module.styl"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { store } from "../store"

export default () => {
  const { state, dispatch } = useContext(store);
  const { drawerMenuOpen } = state;

  const toggleDrawerMenu = () => {
    dispatch({ type: "toggleDrawerMenu" })
  }

  const menuItems = [
    { to: "/landing#intro", label: "home" },
    { to: "/landing#about", label: "about" },
    { to: "/landing#contacts", label: "contacts" },
  ]

  return (

    <div className={`drawer-menu ${style.root} ${drawerMenuOpen && "open"}`}>
      <div className="background" onClick={toggleDrawerMenu}></div>
      <header>
        <div
          className={drawerMenuOpen && 'to-close'}
          className="hamburger-menu-icon"
          onClick={toggleDrawerMenu}
        >
          <span />
          <span />
          <span />
          <span />
        </div>
      </header >
      <ul className="menu-items vertical">
        {
          menuItems.map((it) => {
            return (
              <li key={it.to}>
                <AnchorLink to={it.to} onAnchorLinkClick={toggleDrawerMenu}>{it.label}</AnchorLink>
              </li >
            )
          })
        }
      </ul >
    </div >
  )
};
