import React from "react";
import {
  useStaticQuery,
  graphql
} from "gatsby";


export default () => {

  const {
    strapiContacts,
  } = useStaticQuery(query);

  return (
    <footer>
      {strapiContacts.body}
    </footer>
  )
}

const query = graphql`
query Footer {
  strapiContacts {
    body
  }
}
`