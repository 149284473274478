import React, { useContext } from "react";
import Seo from "./seo";
import Header from "./header"
import DrawerMenu from "./drawerMenu"
import Footer from "./footer"
import { store } from "../store"

const Layout = ({ forwardedRef, children, seo, contentStyle = "", disableHeader, disableFooter }) => {

  const { state } = useContext(store);
  const { drawerMenuOpen } = state;

  return (
    <main ref={forwardedRef} id="page" className={"root " + (drawerMenuOpen && 'drawer-menu-open')}>
      <Seo seo={seo} />
      {!disableHeader && (<Header>Header</Header>)}
      <main className={`${contentStyle}`}>{children}</main>
      {!disableHeader && (<DrawerMenu />)}
      {!disableFooter && (<Footer />)}

      <div className="vignetting" />
    </main>
  )
}

export default Layout;