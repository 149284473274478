import React, { useContext } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import style from "./header.module.styl"
import { store } from "../store"
import LogoType from "./logoTypo"
import Logo from "./logo"

const MenuItem = ({ children }) => {
  return (
    <div className={"menu-item " + style.menuItem}>{children}</div >
  )
}

export default () => {

  const { state, dispatch } = useContext(store);
  const { drawerMenuOpen } = state;

  const toggleDrawerMenu = () => {
    dispatch({ type: "toggleDrawerMenu" })
  }

  const menuItemsLeft = [

    { to: "/landing#about", label: "about" },

  ]

  const menuItemsRight = [
    { to: "/landing#contacts", label: "contacts" },
  ]

  return (
    <header className={"main-header " + style.mainHeader + " " + (drawerMenuOpen && " drawer-menu-open")}>
      <menu>
        <ul className="menu-items left">
          {
            menuItemsLeft.map(it => (
              <li key={it.to}>
                <AnchorLink to={it.to}>
                  <MenuItem>{it.label}</MenuItem>
                </AnchorLink>
              </li>
            ))
          }
        </ul>
        <AnchorLink className="logo" to="#intro">
          <Logo />
        </AnchorLink>
        <AnchorLink className="logoTypo" to="#intro">
          <LogoType />
        </AnchorLink>
        <ul className="menu-items right">
          {
            menuItemsRight.map(it => (
              <li key={it.to}>
                <AnchorLink to={it.to}>
                  <MenuItem>{it.label}</MenuItem>
                </AnchorLink>
              </li>
            ))
          }
        </ul>
      </menu>
      <div
        className={"hamburger-menu-icon " + (drawerMenuOpen && "to-close")}
        onClick={toggleDrawerMenu}
      >
        <span />
        <span />
        <span />
        <span />
      </div>
    </header >
  )
};
